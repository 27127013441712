import Image1 from "../assets/Image1.jpg";
import Image2 from "../assets/Image2.jpg";
import Image3 from "../assets/Image3.jpg";

export const Data = [
  {
    id: 1,
    image: Image1,
    title: "Deepak Aacharya",
    description:
      "A really good job, all aspects of the project were followed step by step and with good results.",
  },
  {
    id: 2,
    image: Image2,
    title: "Suman Chalise",
    description:
      "A really good job, all aspects of the project were followed step by step and with good results.",
  },
  {
    id: 3,
    image: Image3,
    title: "Manish Thapaliya",
    description:
      "A really good job, all aspects of the project were followed step by step and with good results.",
  },
];
